import React from "react";
import { Container, Typography, Box, Button } from "@mui/material";
import CheckCircleOutlineIcon from "@mui/icons-material/CheckCircleOutline";

const BookingConfirm = () => {
  return (
    <Container maxWidth="sm" style={{ textAlign: "center", marginTop: "50px" }}>
      <Box display="flex" flexDirection="column" alignItems="center">
        <CheckCircleOutlineIcon style={{ fontSize: "100px", color: "green" }} />
        <Typography variant="h4" gutterBottom>
          Thank You!
        </Typography>
        <Typography variant="h6" gutterBottom>
          Your booking has been confirmed.
        </Typography>
        <Typography variant="body1" gutterBottom>
          We appreciate your business and hope you have a great flight.You will
          receive flight itinerary confirmation soon via email.
        </Typography>

        <Button
          variant="contained"
          color="primary"
          onClick={() => (window.location.href = "/")}
          sx={{ marginTop: "20px", backgroundColor: "#FC3C00 !important" }}
        >
          Go to Homepage
        </Button>
      </Box>
    </Container>
  );
};

export default BookingConfirm;
