import React from "react";
import "./Cards.css";
// import CardItem from './CardItem';
import WhyBook from "./pages/home/components/WhyBook";
import AccordionsSection from "./AccordionsSection";
import { makeStyles } from "@material-ui/core";
// import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import { Accordion, Container, Grid, Paper } from "@mui/material";
import flyingplane from "../imagesmy/flyingplane.svg";
import GreyCardSection from "./GreyCardSection";
import ReviewSection from "./pages/home/components/ReviewSection";
import blogimg from "../imagesmy/relatedblog.svg";
import mainblog from "../imagesmy/mainblogbanner.svg";
import BookSection from "./BookSection";
import { Link } from "react-router-dom";

const useStyles = makeStyles((theme) => ({
  title: {
    fontFamily: "Swis721 BT",
    fontSize: "20px",
  },
}));

function Cards() {
  return (
    <>
      <Container maxWidth="lg">
        <Grid container spacing={2} justifyContent="center" alignItems="center">
          <Grid item xs={12} sm={6}>
            <Paper style={{ padding: 20 }} elevation={0}>
              <span className="story-tag">Our Story</span>
              <h2>Why our customers love Us?</h2>
              <p>
                Presenting the most dependable travel solutions from London to
                the world over. We are trusted widely by people. Our customers
                can completely depend on us for an end to end travel solutions,
                while all they need to do is pack their bags and arrive in style
              </p>
            </Paper>
          </Grid>
          <Grid className="d-lg-block d-none" item xs={12} sm={6}>
            <Paper style={{ padding: 20 }} elevation={0}>
              <img src={flyingplane} alt="" />
            </Paper>
          </Grid>
        </Grid>
      </Container>

      <WhyBook />
      <GreyCardSection />
      <div className="cards">
        {/* this section for cards */}
        <div className="cards__container">
          <h2>Check out these EPIC Destinations!</h2>
          <div className="cards__wrapper">
            {/* <ul className='cards__items'>
              <CardItem
                src='images/Mauritius.jpeg'
                text='Combine swimming with dolphins and whale watching in Mauritius in a hassle-free combo tour'
                label='Mauritius'
                path='/services'
              />
              <CardItem
                src='images/Nairobi.jpeg'
                text='Enjoy a safari within sight of the city skyline.'
                label='Nairobi'
                path='/services'
              />
            </ul>
            <ul className='cards__items'>
              <CardItem
                src='images/logos.jpeg'
                text=' Nigeria’s largest city, sprawls inland from the Gulf of Guinea across Lagos Lagoon'
                label='Lagos'
                path='/services'
              />
              <CardItem
                src='images/Calgary.jpeg'
                text=' Spend a Day Outdoors in Fish Creek Park'
                label='Calgary'
                path='/products'
              />
              <CardItem
                src='images/Montego.jpeg'
                text='Visit Jamaicas fascinating Mystic Lagoon on a night cruise!'
                label='Montego Bay'
                path='/sign-up'
              />
            </ul> */}
          </div>
        </div>
      </div>

      <ReviewSection />
      <Container maxWidth="lg">
        <Grid container spacing={2} justifyContent="center">
          <Grid item xs={12} sm={6}>
            <Paper style={{ padding: 20 }} elevation={0}>
              <Link to="/BlogDetails">
                <div className="main-blog">
                  <img className="img-fluid" src={mainblog} alt="" />
                  <div className="main-info">
                    <span className="text-white">OCT 26, 2023</span>
                    <h4 className="main-banner-title text-white">
                      Our top reasons to visit the Maldives
                    </h4>
                    <a className="seemore text-white" href="#">
                      see more
                    </a>
                  </div>
                </div>
              </Link>
            </Paper>
          </Grid>
          <Grid className="d-lg-block d-none" item xs={12} sm={6}>
            <Paper style={{ padding: 20 }} elevation={0}>
              <Link to="/BlogThailand">
                <div className="related-blogs mb">
                  <img src={blogimg} alt="" />
                  <div>
                    <span className="date">September 25, 2023</span>
                    <div className="blog-title">
                      Which Thai island is right for you?
                    </div>
                    <a className="seemore" href="#">
                      see more
                    </a>
                  </div>
                </div>
              </Link>
              <Link to="/BlogDetaailsDubai">
                {" "}
                <div className="related-blogs">
                  <img src={blogimg} alt="" />
                  <div>
                    <span className="date">September 25, 2023</span>
                    <div className="blog-title">
                      Our top reasons to visit the Dubai
                    </div>
                    <a className="seemore" href="#">
                      see more
                    </a>
                  </div>
                </div>
              </Link>
            </Paper>
          </Grid>
        </Grid>
      </Container>
      <AccordionsSection />

      <BookSection />
    </>
  );
}

export default Cards;
