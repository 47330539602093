import React from "react";
import Footer from "../.././Footer";
import Navbar from "../../Navbar";
import { Topnav } from "../../Topnav";
import "./privacypolicy.css";
export const PrivacyPolicy = () => {
  return (
    <>
      <Topnav />
      <Navbar />
      <div className="policy-container">
        <h2>Privacy Policy</h2>
        <h4>1. Introduction </h4>
        _____________________________________________________
        <br />
        <p1>
          This privacy notice lets you know what happens to any personal data
          that you give to us, or any that we may collect from or about you. It
          applies to personal information processed by or on behalf of Bliss
          Flights.
          <br />
          <br />
          We may change this privacy notice on occasion to reflect changes in
          the law and/or our privacy practices. We encourage you to check this
          privacy notice for changes whenever you visit our website:
          https://bliss-flights.co.uk/privacy_policy
          <br />
          <br />
        </p1>
        <h4>2. The types of personal information we hold and process</h4>
        _____________________________________________________
        <br />
        <p1>
          We will process personal data in connection with all of our products
          and services, if relevant, including: <br />
          • Personal and contact details, including but not limited to title,
          full name and contact details • Records of your contact with us such
          as via the telephone, through one of our website contact forms, or by
          email <br />
          • Your expressed travel interests, including destination and holiday
          types • Products and services you have booked with us, as well as
          those you have been interested in • Your date of birth, gender, age
          (where provided) • Passport information and your nationality, if
          supplied for the purposes of processing your booking
          <br />
          • Your residency and/or citizenship status, if relevant and required
          for the processing of your booking
          <br />
          • Family members, if supplied for the purposes of processing your
          booking • Information about your health and dietary requirements, if
          supplied for the purposes of processing your booking <br />
          • Your marital status and family, if supplied for the purposes of
          processing your booking • Post travel follow up communications,
          including reviews, complaints and general feedback • Marketing to you,
          including history of our marketing communications, whether you open
          digital communications or click on links, and information about
          products or services we think you may be interested in. <br />
          • Insights about you gained from analysis or profiling of our database
          and the information we hold about you
          <br />
          • Online behavioural data including IP Address, Browser Type, Browser
          Version and pages of website visited
          <br />
          <br />
        </p1>
        <h4>3. The sources of the personal information we hold about you</h4>
        _____________________________________________________
        <p1>
          We typically collect personal information from the following sources:
          • Information supplied by you when you request a holiday quotation
          from us, either over the telephone, via email, through one of our
          website contact forms, call back request or web chat. • Information
          supplied by you when book a holiday with us
          <br />• If you have cookies enabled on your computer, we may also
          collect information that your browser sends us whenever you visit our
          website. This data may include information such as your computer’s IP
          address, browser type, browser version, the pages of our Website that
          you visit and other statistics relating to your use of the Website.
          This information may be collected in conjunction with third party
          services such as Google Analytics and Adalyser, an analytics platform
          that is used to measure response to TV advertising. The information we
          collect is aggregated to produce statistics that cannot be linked to
          you. We use this information to provide you with a better experience,
          to improve our services, and to analyze and understand how our website
          and products are used.
        </p1>
        <h4>4. What we use your personal data for</h4>
        _____________________________________________________
        <br />
        <p1>
          We use your personal data, including any of the personal data listed
          in section one above, for the following purposes: <br />
          • Supplying you with the product or service you have booked with us
          <br />
          • Providing you with information, such as a holiday quotation, that
          you have requested from us
          <br />
          • To provide insight and analysis of our customers and staff, to help
          us improve products and services, and to assess or improve our
          business operations
          <br />
          • To share information, as needed, with business partners and service
          providers (for example, hotels, airlines, ground agents) as part of
          our contract with you to provide and administer the products and
          services you have purchased from us
          <br />
          • To comply with legal and regulatory obligations and requirements{" "}
          <br />
          • Updating your records and the information we hold about you
          <br />
          • To improve the operation of our business and that of our business
          partners, including hotel partners, ground agents and airlines
          <br />
          • For market research and analysis of our customers and business
          operations • For the management and auditing of our business
          operations • To detect and prevent fraud
          <br />
          • To monitor and to keep records of our communications with you and
          our staff <br />
          • For direct marketing communications, including customer profiling to
          help us to offer you products and service which we believe to be
          relevant to your interests, including deciding whether or not to offer
          you certain products and service. We'll communicate to you by email,
          phone, post, and other digital channels (for example, via Google
          Display Network/Custom Match and Facebook Custom Audiences). <br />
          • To provide personalized content and services to you, such as
          tailoring our products and services, our digital customer experience
          and other communications
          <br />
          • To review and improve current products and services, as well as
          develop new ones
          <br />
        </p1>
        <h4>
          5. The legal grounds for our processing of your personal information
        </h4>
        ________________________________________
        <br />
        <p1>
          We use the following legal bases to use your personal data: <br />
          a. Where it is needed to provide you with our products or services,
          such as: • All activities relevant to delivering the product or
          service including enquiry, administration and processing of your
          booking, post-travel communications
          <br />
          • Managing bookings you hold with us
          <br />
          • Sharing your personal information with business partners and
          services providers, including hotels, airlines and ground agents
          <br />
          • With your consent or explicit consent: <br />
          • For direct marketing communications
          <br />
          • For profiling and other automated decision making
          <br />
          • For, where required, processing of special categories of personal
          data such as that about your health or criminal records information.{" "}
          <br />
          • To comply with our legal obligations
          <br />
          • Where it is in our legitimate interests to do so, such as: • For
          market research and analysis <br />
          • For direct marketing communications, including customer profiling to
          help us to offer you products and service which we believe to be
          relevant to your interests, including deciding whether or not to offer
          you certain products and service. We'll communicate to you by email,
          phone, post, and other digital channels (for example, via Google
          Display Network/Custom Match and Facebook Custom Audiences). <br />
          • Where we need to share your personal information with partner
          organizations in order to run our business or comply with legal and/or
          regulatory obligations that govern our business operations. <br />
        </p1>
        <h4>6. Do you have to provide your personal information to us? </h4>
        _____________________________________________________
        <br />
        <p1>
          In circumstances where providing personal information is
          non-compulsory to the delivery of our products or services, we'll make
          this clear in our communications to you. However we will be unable to
          provide you with our products or services if you do not provide
          certain information to us
        </p1>
        <h4>7. Direct marketing by us and your marketing preferences </h4>
        _____________________________________________________
        <br />
        <p1>
          We may use your home address, phone number(s), email address and
          social media channels (for example, Facebook, Google and message
          facilities in other platforms) to contact you according to the
          marketing preferences we hold on record for you. <br />
          You can request us to stop our marketing at any time by contacting us
          using the details at the bottom of this privacy notice, or those
          provided in the specific communication.
        </p1>
        <h4>
          8. Opting out of or withdrawing your consent to direct marketing
        </h4>
        _____________________________________________________
        <br />
        <p1>
          Where we're relying upon your consent to process personal data, you
          can withdraw this at any time. If you no longer want to hear from us,
          you can opt out or unsubscribe by: <br />
          • Clicking on the 'Unsubscribe' link in any of the e-newsletters we
          send (this applies to email communications only) <br />
          • Emailing us at info@bliss-flights.co.uk, <br />
          • Or telephoning us at 020 3831 3448
          <br />
          You can also use the contact details above to advise us of any changes
          to your personal information and we'll update your records
          accordingly. <br />
        </p1>
        <h4>
          9. When we share your personal information with other organizations
        </h4>
        _____________________________________________________
        <br />
        <p1>
          We may share information with the following third parties for the
          purposes of providing you with the products and services you have
          requested from us (such as a holiday quotation) as well as when you
          book with us. These third parties include our hotel partners, ground
          agents and airlines. <br />
        </p1>
        <h4>
          10. When your personal information is transferred outside the UK or
          the EEA
        </h4>
        _____________________________________________________
        <br />
        <p1>
          Bliss Flights is based in the UK but when you request/book a product
          or service from us that involves a business partner outside of the UK
          or EEA, we will share your personal information outside the European
          Economic Area. In these instances we endeavor to ensure suitable
          safeguards are in place, for example by using contractual agreements
          to stipulate the safe processing of the data we share. <br />
        </p1>
        <h4>
          11. Our monitoring of communications involving processing of your
          personal information
        </h4>
        _____________________________________________________
        <br />
        <p1>
          For the purposes of this privacy notice, monitoring means any:
          listening to and recording of calls, viewing of, intercepting of or
          saving emails and social media messages, keeping records of meetings
          and other communications. <br />
          We may monitor communications where permitted by law to comply with
          regulatory rules and to prevent or detect crime, as well as to enforce
          procedures for quality control and staff training purposes. <br />
        </p1>
        <h4>12. Keeping your data secure </h4>
        _____________________________________________________
        <br />
        <p1>
          We will use technical and organizational measures to safeguard your
          personal data, for example: <br />
          • We store your personal data on secure servers; <br />
          • When your credit or debit card account information is being
          transmitted to our it will be protected by cryptographic protocols. To
          be clear, Bliss Flights does not itself store your credit or debit
          card account information, and we do not have direct control over or
          responsibility for your credit or debit card account information. We
          use third party payment processors that are the controllers of your
          credit card information
          <br />
          While we will use all reasonable efforts to safeguard your personal
          data, you acknowledge that the use of the internet is not entirely
          secure and for this reason we cannot guarantee the security or
          integrity of any personal data that are transferred from you or to you
          via the internet. If you have any particular concerns about your
          information, please contact us (see 'Contacting Us' below). <br />
        </p1>
        <h4>13. Data retention</h4>
        _____________________________________________________
        <br />
        <p1>
          We will hold your personal information based on the following
          criteria: <br />
          • For as long as required for us to provide goods and/or services to
          you and subsequently for as long as you are legally entitled to bring
          a claim against us; and/or
          <br />
          • Retention periods in line with legal and regulatory requirements or
          guidance; and/or
          <br />
          • For as long as we have reasonable business needs
          <br />
          We only keep your personal data as long as necessary for the purpose
          for which it was obtained. After that period, we either: (1) anonymize
          the data if we still wish to use it for analytical purposes, or (2)
          pseudonyms the data if believe in good faith that we may need to
          process the data in the future for a legitimate purpose, or in all
          other cases (3) delete it completely from our servers. <br />
        </p1>
        <h4>14. Your rights under data protection laws</h4>
        _____________________________________________________
        <br />
        <p1>
          The Data Protection Act gives rights to individuals in respect of the
          personal data that organizations hold about them. Below is a list of
          these rights. It is important to note that they don't all apply in all
          circumstances. <br />
          • a right of access to a copy of the information comprised in your
          personal data; <br />
          • a right to object to processing that is likely to cause or is
          causing damage or distress; <br />
          • a right to prevent processing for direct marketing; <br />
          • a right to object to decisions being taken by automated means;{" "}
          <br />
          • a right in certain circumstances to have inaccurate personal data
          rectified, blocked, erased or destroyed. <br />
        </p1>
        <h4>Contacting Us</h4>
        _____________________________________________________
        <br />
        <br />
        <p1>
          If you have any questions about this privacy notice, or if you wish to
          exercise your rights or contact the DPO, you can contact us by: <br />
          • Emailing us at info@bliss-flights.co.uk , <br />
          • Or telephoning us at 020 3831 3448
          <br />
          Bliss Flights |Privacy Notice v2 | 27.04.2022
        </p1>
      </div>
      <Footer />
    </>
  );
};
