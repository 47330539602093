import React from "react";
// import "../App.css";
import { Paper, makeStyles, Container } from "@material-ui/core";
import FlightForm from "./FlightForm";
import "./HeroSection.css";

const useStyles = makeStyles((theme) => ({
  pageContent: {
    margin: theme.spacing(8),
    padding: theme.spacing(3),

    // opacity: 0,
  },
}));

function HeroSection() {
  const classes = useStyles();

  return (
    <>
      <div className="hero-container">
        {/* <video src='/videos/airplane.mp4' autoPlay loop muted /> */}

        <div className="custom-container">
          <div className="text-left text-white mt-60">
            <span className="fly-tag">Lets Fly</span>
            <h1>Ready To Take Off?</h1>
          </div>
        </div>
      </div>

      <div className="custom-container">
        <Paper className={classes.pageContent}>
          <FlightForm />
        </Paper>
      </div>
    </>
  );
}

export default HeroSection;
