import React from "react";
import phone from "..//imagesmy/phone.svg";

import "./topnav.css";

export const Topnav = () => {
  return (
    <>
      <div className="topnav">
        <div className="top-nav-container custom-container">
          <div className="top-nav-left-container">
            <div>
              <a href="tel:020 3927 7690" className="text-white fw-bold">
                020 3927 7690
              </a>
            </div>

            <div className="econtainer">
              <h3 className="email">
                <a className="email a" href="mailto:info@bliss-flights.co.uk">
                  info@bliss-flights.co.uk
                </a>{" "}
              </h3>
            </div>

            <div className="pcontainer">
              <h3 className="phone ">
                {/* <PhoneIphoneTwoTone /> */}

                <img className="phoneicon" sx={{ pr: 1 }} src={phone} alt="" />
                <a href="tel:020-3927-7690 text-white">+442038313448</a>
              </h3>
            </div>
          </div>

          <div className="scontainer">
            <div className="social-icons-top">
              <div className="social-links">
                <a href="www.bliss-flights.co.uk">
                  <i className="fab fa-facebook-f"></i>
                </a>
                <a href="www.bliss-flights.co.uk">
                  <i className="fab fa-twitter"></i>
                </a>
                <a href="www.bliss-flights.co.uk">
                  <i className="fab fa-instagram"></i>
                </a>
                <a href="www.bliss-flights.co.uk">
                  <i className="fab fa-linkedin-in"></i>
                </a>
              </div>

              {/* <button>English</button> */}
            </div>
          </div>
        </div>
      </div>
    </>
  );
};
