import React, { useContext, useState } from "react";
import "./App.css";

import { BrowserRouter as Router, Route, Routes } from "react-router-dom";
import Services from "./components/pages/about-us/AboutUs";
import Products from "./components/pages/flights/Flights";
import SignUp from "./components/pages/holidays/Holidays";
import ContactUs from "./components/pages//contact-us/ContactUs";

import Admin from "./dashboard/Admin";
import Userlist from "./dashboard/pages/userlist/Userlist";
import Airline from "./dashboard/pages/airlines/Airlines";
import Locations from "./dashboard/pages/destination/Locations";
import Fclasses from "./dashboard/pages/classes/Fclasses";
import SeasionalFares from "./dashboard/pages/seasonalfares/SeasonalFares";
import { Terms } from "./components/pages/terms/Terms";
import { PrivacyPolicy } from "./components/pages/privacy/PrivacyPolicy";
import Register from "./dashboard/pages/userlist/Register";
import Login from "./dashboard/pages/userlist/Login";
import AddFlight from "./dashboard/pages/addflight/AddFlight";
// import { Navigate } from "react-router-dom";
import ProtectedRoute from "./dashboard/pages/userlist/ProtectedRoute";
import ScrollToTop from "./components/ScrollToTop";
import CheckOut from "./components/pages/CheckOut";
import BlogDetails from "./components/BlogDetails";
import BlogThailand from "./components/BlogThailand";
import BlogDetaailsDubai from "./components/BlogDetaailsDubai";
import PopUp from "./dashboard/pages/popup/popUp";
import Home from "./components/pages/home/Home";
import BookFlight from "./components/pages/book-flight/BookFlight";
import BookingConfirm from "./components/pages/booking_confirm/BookingConfirm";
import { Helmet } from "react-helmet";
function App() {
  return (
    <>
      <Helmet>
        {/* Google Tag Manager */}
        <script>
          {`
            (function(w,d,s,l,i){w[l]=w[l]||[];w[l].push({'gtm.start':
            new Date().getTime(),event:'gtm.js'});var f=d.getElementsByTagName(s)[0],
            j=d.createElement(s),dl=l!='dataLayer'?'&l='+l:'';j.async=true;j.src=
            'https://www.googletagmanager.com/gtm.js?id='+i+dl;f.parentNode.insertBefore(j,f);
            })(window,document,'script','dataLayer','GTM-WLT6TB82');
          `}
        </script>
        {/* End Google Tag Manager */}
      </Helmet>
      <noscript>
        <iframe
          src="https://www.googletagmanager.com/ns.html?id=GTM-WLT6TB82"
          height="0"
          width="0"
          style={{ display: "none", visibility: "hidden" }}
        ></iframe>
      </noscript>
      <Router>
        <ScrollToTop />
        <Routes>
          <Route exact path="/" element={<Home />} />
          <Route path="/about-us" element={<Services />} />
          <Route path="/flights" element={<Products />} />
          <Route path="/Holidays" element={<SignUp />} />
          <Route path="/contact-us" element={<ContactUs />} />
          <Route path="/booking-confirm" element={<BookingConfirm />} />

          <Route path="/book-flight" element={<BookFlight />} />

          <Route path="/terms_conditions" element={<Terms />} />
          <Route path="/privacy_policy" element={<PrivacyPolicy />} />
          <Route path="/checkout" element={<CheckOut />} />
          <Route path="/BlogDetails" element={<BlogDetails />} />
          <Route path="/BlogThailand" element={<BlogThailand />} />
          <Route path="/BlogDetaailsDubai" element={<BlogDetaailsDubai />} />

          {/* this section for admin panel */}

          <Route path="/login" element={<Login />} />

          <Route
            path="/admin"
            element={
              <ProtectedRoute>
                {" "}
                <Admin />
              </ProtectedRoute>
            }
          />
          <Route
            path="/user"
            element={
              <ProtectedRoute>
                <Userlist />
              </ProtectedRoute>
            }
          />
          <Route path="/airline" element={<Airline />} />
          <Route
            path="/locations"
            element={
              <ProtectedRoute>
                <Locations />
              </ProtectedRoute>
            }
          />
          <Route
            path="/addflight"
            element={
              <ProtectedRoute>
                <AddFlight />
              </ProtectedRoute>
            }
          />

          <Route
            path="/seasional_fares"
            element={
              <ProtectedRoute>
                <SeasionalFares />
              </ProtectedRoute>
            }
          />
          <Route
            path="/pop_up"
            element={
              <ProtectedRoute>
                <PopUp />
              </ProtectedRoute>
            }
          />
          <Route
            path="/f_classes"
            element={
              <ProtectedRoute>
                <Fclasses />
              </ProtectedRoute>
            }
          />

          <Route
            path="/register"
            element={
              <ProtectedRoute>
                <Register />
              </ProtectedRoute>
            }
          />
        </Routes>
      </Router>
    </>
  );
}

export default App;
