import React from "react";
import { Button } from "@mui/material";
import "./Whybook.css";

const SliderCard = ({ title, buttonLabel, imageUrl, background }) => {
  const cardStyle = {
    background: background, // Apply the background color directly to the card container
  };

  return (
    <div className="slider-card" style={cardStyle}>
      <div className="slider-heading">{title}</div>

      <Button className="book-btn" variant="contained" color="primary">
        {buttonLabel}
      </Button>

      <img height="150" width="150" src={imageUrl} alt="" />
    </div>
  );
};

export default SliderCard;
