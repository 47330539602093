import React from "react";
import Footer from "../.././Footer";
import Navbar from "../../Navbar";
import { Topnav } from "../../Topnav";
import "./terms.css";
export const Terms = () => {
  return (
    <>
      <Topnav />
      <Navbar />
      <div className="term-container">
        <h2>TERMS AND CONDITIONS</h2>
        <h4>
          • IT IS IMPORTANT THAT YOU ARE AWARE OF THE FOLLOWING TERMS AND
          CONDITIONS
        </h4>
        <h4>1.Contract</h4>
        <p1>
          Your contract is with Bliss Flights Ltd. These conditions form part of
          that contract for all arrangements you book with us.
          <br />
          <br />
        </p1>
        <br />
        <br />
        <h4>2.Payments</h4>
        <p1>
          The payment required at the time of booking will depend on the
          arrangements you choose. Full payment is usually required immediately.
          Although a provisional reservation for a scheduled flight may be made
          on payment of a deposit, normally 35% of the total cost of these
          arrangements or a minimum of £100, (whichever is the greater), and
          must be paid as a deposit at the time of booking. However, on payment
          of deposit only, a confirmed booking cannot be made, nor can the price
          be guaranteed, until full payment for the ticket has been received and
          the ticket issued.
          <br />
          Deposit payments are 100% non-refundable.
          <br />
          <br />
          Note: airlines are at liberty to change prices, or cancel the booking,
          at any time without prior notification. If either of these actions
          should happen, additional charges may occur. If full payment is not
          made at the time of booking, we must receive the balance no later than
          the date specified on the travel confirmation, or if no date is
          specified, no later than 10 weeks before your departure date. If we do
          not receive full payment by the due date, then we reserve the right to
          treat your booking as cancelled by you and cancellation charges will
          be applicable. Payment will be accepted by: Visa Debit, Visa Credit,
          MasterCard, and Master Debit Cards. Bank Deposit/Transfer (Details
          available from your consultant).
        </p1>

        <br />
        <br />
        <h4>3.Cost of Air Fares (Other Arrangements)</h4>
        <p1>
          Airfares and their conditions can change without warning, and
          customers who have paid only a deposit amount should be aware, a
          deposit is only reserving the seat, NOT the price of the ticket.
          Airlines are at liberty to change prices at any time up to the date of
          travel and this can apply to issued tickets, but in practice, airlines
          usually honour prices where tickets have been issued. For customers
          that have only paid a deposit for air tickets, Bliss Flights LTD will
          attempt to provide advance warning of price increases in an effort to
          allow you to pay at the original price, but cannot guarantee to do so.
          <br />
          Most discounted air tickets are Non-refundable, Non-changeable and
          Non-transferable, unless otherwise specified. Please check whether you
          need a changeable ticket.
          <br />
          Passengers must check their booking confirmation documentation to
          ensure that all name spellings are correctly as per their passports
          and that the travel itinerary is correct. Changes may not be
          permissible once tickets are issued, and a fee for a new ticket may be
          charged.
          <br />
          Please ensure that the dates and times of your flights are correct and
          the names of travellers with their date of birth are accurate as per
          the corresponding passport. Tickets are non-transferable and name
          changes are not permitted. In some cases, the airline may not allow
          any changes and may demand a full re-purchase of the product. <br />
          Passengers must be at the airport and available to check-in at least
          three hours prior to departure. Tickets cannot be refunded or changed
          due to passenger no show at the airport. Passengers are responsible
          for ensuring they have all the correct travel documentation including
          visas. Visas may be required for the entire journey, both for the
          destination and/or transit. Visas must be secured before ticket issue.
          Ticket refunds cannot be made in the event of the passenger’s failure
          to obtain the correct visa. <br />
          We need your mobile number so that we may call or text you about any
          changes to your itinerary and to provide helpful information about
          your trip. <br />
          Total price shown includes all applicable taxes and fees. Some
          airlines may charge additional baggage fees or other fees. Fares are
          not guaranteed until ticketed.
          <br />
          <br />
        </p1>

        <h4>4.Airport Charges and Departure Taxes</h4>
        <p1>
          Action by UK or Overseas Governments and other Authorities including
          mandatory increases or new charges are not covered by the company’s
          price Guarantee. UK Air Passenger Duty, together with the Airport and
          Departure Taxes, will be charged separately to the fares as per
          invoice.
          <br />
          <br />
        </p1>
        <h4>5.Accuracy</h4>
        <p1>
          Whilst we make every effort to ensure the accuracy of our work, please
          carefully check all passenger and flight itinerary details as soon as
          you receive them and immediately advise us of any discrepancies. The
          name shown on all travel documents should match those on your
          passport(s). If you are getting married, or changing your name, the
          name(s) on your passport should reflect those on your travel
          documents. Failure to comply with this could result in amendment
          charges being levied, or possible cancellation of your flights.
          <br />
          <br />
        </p1>

        <h4>6.Amendments</h4>
        <p1>
          Should you wish to make any changes to your arrangements after they
          have been confirmed, we will endeavour to assist but cannot guarantee
          we will be able to do so. Amendment fees also vary from supplier to
          supplier so you must consult with us to establish the cost dependant
          on the type of change you wish to make.
          <br />
          Where the number of persons travelling is changed, the price for the
          booking will be recalculated on the new number of persons where
          applicable. In most cases, name changes are not permitted by the
          airlines and they will almost always levy 100% cancellation charges. .
          <br />
          If you wish to alter the dates of your existing arrangements prior to
          payment of your balance, each amendment will incur a charge of £35 per
          person. Amendment charges will be added to your invoice and are
          non-refundable. Any additional charges passed on to us by airlines or
          suppliers will also be added to your invoice. We will always do our
          utmost to meet new instructions, but will only do so after instruction
          is receive in writing. .<br />
          Please note that we cannot make any refunds for unused flight, car
          hire or hotel vouchers etc. irrespective of what you are told by the
          supplier. Ground arrangement vouchers are only valid for the specific
          services for which they are issued. Please carefully read any vouchers
          and documentation sent to you as they contain useful information
          <br />
          <br />
        </p1>
        <h4>7.Cancellation</h4>
        <p1>
          This is where you wish to cancel your entire holiday arrangements, or
          a component of your holiday. The person who made the booking must
          notify us of any cancellation requests in writing. Prior to payment of
          balance - If your entire travel arrangement is cancelled, all moneys
          paid will be forfeit. If a component of your holiday is cancelled, a
          charge of £35 per person will be levied. Where additional moneys have
          been paid, relating to a specific component, these moneys will be
          forfeit. After full payment is made, but before departure – Most
          airfares carry a 100% cancellation charge, but where a cancellation
          can be arranged, charges are 15% of the total cost, plus loss of
          deposit and any insurance premium paid. Any additional cancellation
          charges passed on to us by airlines or suppliers will be additional to
          this. .<br />
          Many of our airfares have special conditions attached to them, which
          usually incur cancellation charges as high as 100%. These will be
          applied in addition to the above charges. On or after departure
          cancellation charges will consist of those applied by the airline or
          operator and those of Bliss Flights. These may amount to the loss of
          all money paid even where a particular element remains wholly or
          partially unused. .<br />
          Note: If the reason for cancellation is covered under the terms of an
          insurance policy, you may be able to reclaim these charges. You will
          be advised of the exact amount prior to the cancellation being
          finalised.
          <br />
          <br />
        </p1>
        <h4>8.Refund</h4>
        <p1>
          Unused or part used air tickets are returned to the airline for
          authorisation and calculation of any refund due. Any refund received
          back from the airline is then subject to loss of deposit and 15%
          cancellation charges, unless advised differently at the time of
          booking. Refunds can take anywhere between two and six weeks and in
          some extra-ordinary circumstances up to six months, depending on the
          nature of the booking, to be dealt with by both the airline and Bliss
          Flight Ltd. In most cases, Bliss Flights is not permitted to make any
          refund without reference to the airline. Some airlines may require
          proof of departure from the destination country or a residency
          certificate, before refunding part used tickets. .<br />
          Deposit payments are 100% non-refundable
          <br />
          <br />
        </p1>
        <h4>9.Force Majeure</h4>
        <p1>
          This means that you will not be entitled for a full refund and we will
          not pay you compensation if we need to cancel or change your travel
          arrangements in any way because of unusual or unforeseeable
          circumstances beyond our control. These can include, for example, war,
          riot, industrial dispute, terrorist activity and its consequences,
          natural or nuclear disaster (such as volcanic ash), fire, adverse
          weather conditions and unavoidable technical problems with transport.
          Normal cancellation charges will be applied.
          <br />
          <br />
        </p1>
        <h4>10.Reconfirmation of Flights / Check-in</h4>
        <p1>
          On arrival at any destination, or stopover point, it is imperative
          that you reconfirm the reservations, timings and check-in details of
          your flight with the airline concerned, at least 72 hours prior to
          departure. This also applies to your first flight from the UK. This
          action allows the airline to inform you of any unforeseen delays or
          changes to your itinerary and provides the airlines with a contact for
          you.
          <br />
          <br />
        </p1>
        <h4>11.Routing and Timings</h4>
        <p1>
          Unless otherwise stated, the routing of your air ticket cannot be
          changed once the balance has been paid. Please note that the timings
          of air departures are estimates only. These timings may alter if
          affected by operational difficulties, weather conditions or passenger
          issues, such as failure to check in on time. Flight timings may be
          subject to change as a result of airline procedures, up to date times
          will appear on your final itinerary. Please also note carriers have
          conditions of carriage which will apply to you and which limit or
          exclude liability. Copies are available from the relevant airlines
          website. We do not make arrangements in the event of an airline delay
          disrupting any leg of the outbound or inbound points of your flight.
          Most airlines however, do make provision for such instances.
          <br />
          <br />
        </p1>
        <h4>12. Special Requests</h4>
        <p1>
          In the event of making requests such as dietary requirements, seating,
          wheelchair etc. We will communicate your request direct to the
          airline. Bliss Flights cannot guarantee requests as these are the sole
          prerogative of the airline concerned, hence we cannot be held
          responsible for any refusal.
          <br />
          <br />
        </p1>
        <h4>13.Passport and Visa Requirements</h4>
        <p1>
          A valid ten-year passport is required for visits to all destinations.
          It is the customer’s responsibility to check the validity of your
          passport and any visa requirements with the appropriate consulates.
          Also, if appropriate, please check you have a full driving license and
          are eligible to drive in the country concerned. .<br />
          Passports must be valid for at least six months beyond the period of
          your stay. ESTA visa is a mandatory requirement for all USA bound
          travel, including transiting the USA. ETA visa is a mandatory
          requirement for all Australia bound travel, including transiting
          Australia. ETA visa is a mandatory requirement for all Canada bound
          travel, including transiting Canada. NZeTA is a mandatory requirement
          for all New Zealand bound travel, including transiting New Zealand. .
          <br />
          If your flight has a change involving two different airports within
          the itinerary, it is your responsibility to organize any airport
          transfer required, and also to check and secure any transit visa
          required. If you have booked code-share flights, terminal change may
          apply and you may require transit visa for changing terminals. Please
          contact the embassy or airline direct to ascertain correct visa
          requirements in case of terminal change. .<br />
          Bliss Flights LTD is not responsible for any Visa formalities. Please
          consult the relevant embassy or consulate for this information. .
          <br />
          Passengers are responsible for ensuring they have all the correct
          travel documentation including visas. Visas may be required for the
          entire journey, both for the destination and/or transit. Visas must be
          secured before ticket issue. Ticket refunds cannot be made in the
          event of the passenger’s failure to obtain the correct visa. .<br />
          All travellers (including children and infants) will require a valid
          passport to travel. Different countries have their own entry
          requirements, from how much validity your passport requires, to
          whether or not you require a visa. It is important that you make sure
          you are aware of the entry requirements for not only the country you
          are visiting, but also any you may transit via. If your passport is
          endorsed in any way, you should always check with the relevant
          embassy. Please note, getting or renewing passports can take a number
          of weeks, so please leave plenty of time prior to travel. Normal
          recommendation is that you have at least six months validity remaining
          on your passport on your return date, in fact this is now mandatory in
          a number of countries. .<br />
          It is your responsibility to ensure you have all necessary travel
          documents prior to travel. We cannot accept any liability if you are
          refused boarding or entry into a country due to incorrect
          documentation.
          <br />
          <br />
        </p1>
        <h4>14.Health Recommendation</h4>
        <p1>
          Recommended inoculations for travel may change at any time. It is your
          responsibility to ensure that you obtain all recommended inoculations,
          take all recommended medication and follow all medical advice in
          relation to your trip. .<br />
          For more information on avoiding health risks, planning for healthy
          travelling and obtaining emergency medical treatment, visit the NHS
          website.
          <a
            href="http://www.nhs.uk/Livewell/TravelHealth"
            target="_blank"
            rel="noopener noreferrer"
          >
            http://www.nhs.uk/Livewell/TravelHealth
          </a>
          <br />
          <br />
        </p1>
      </div>
      <Footer />
    </>
  );
};
