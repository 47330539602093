import React from "react";
import "./holidays.css";
import Footer from "../../Footer";
import Navbar from "../../Navbar";
import { Topnav } from "../../Topnav";

export default function Holidays() {
  return (
    <>
      <Topnav />
      <Navbar />
      <div className="top-conatainer">
        <div className="holiday-to-img">
          <h1>Holidays</h1>
        </div>
      </div>
      <div className="h-main-conatainer">
        <div className="h-first-container">
          <h3>
            ALL HOLIDAY DEALS & PACKAGES 2022/2023 <br /> BOOK WITH CONFIDENCE
          </h3>
          <p>
            Are you a travel addict looking for a holiday package? Then you are
            on the right page. Every traveler has a great passion for exploring
            the planet, a world, unlike any other, which can be a cost that hits
            your wallet. We make your lives more comfortable with our
            all-holiday deals page featuring the latest deals for your holiday
            getaway. Whether you're a solo traveler looking for adventures, a
            couple for a romantic getaway, or a family looking for a weekend
            break, we've got you covered with our exclusive holiday deals. We
            have any holiday you demand; city breaks, luxury getaways, beach
            holidays, all-inclusive options, holiday package deals for families,
            last-minute holiday deals, ski adventures, including flights and
            accommodation.
          </p>
        </div>
        <div className="h-second-conatainer">
          <div className="h-card-container">
            <div className="first-card">
              <div className="card-image-1"></div>
              <div className="card-content">
                <h4>United States</h4>
                <h5>California & Canyons</h5>
                <p>
                  Begin your all-American self-drive Journey in the bay city of
                  San Francisco. Prepare to have your senses dazzled by the neon
                  lights and showmanship of Sin City, a sizzling metropolis in
                  the middle of the desert. Go celeb-spotting in Hollywood, shop
                  till you drop in Beverly Hills, and hike Runyon Canyon for
                  stunning views over the city.{" "}
                </p>
                <button>
                  <a className="bnumber" href="tel:020-3927-7690">
                    020 3927 7690
                  </a>
                </button>
              </div>
            </div>
            <div className="first-card">
              <div className="card-image-2"></div>
              <div className="card-content">
                <h4>BANGKOK</h4>
                <h5>Bangkok, Jungle & Beach</h5>
                <p>
                  Combining the bustle of Bangkok with the charm of Chiang Mai
                  and the relaxing paradise of Phuket. this Classic Journey
                  offers dazzling temples and historic shrines, mouth-watering
                  local cuisine, and the chance to soak up the sun on one of
                  Thailand’s most beautiful beaches. While chilling out here,
                  why not take a day to explore nearby James Bond Island?
                </p>
                <button>
                  <a className="bnumber" href="tel:020-3927-7690">
                    020 3927 7690
                  </a>
                </button>
              </div>
            </div>
            <div className="first-card">
              <div className="card-image-3"></div>
              <div className="card-content">
                <h4>MAURITIUS</h4>
                <h5>Beyond The Beaches</h5>
                <p>
                  The pristine shores of Mauritius have been attracting foreign
                  visitors for centuries. Its varied history is evident in the
                  colonial plantation homes and Creole cuisine. Nature abounds
                  in the volcanic island’s interior with rainforests,
                  waterfalls, hiking, and mountain bike trails. The beaches of
                  Mauritius are beautiful and worthy of thorough exploration.
                </p>
                <button>
                  <a className="bnumber" href="tel:020-3927-7690">
                    020 3927 7690
                  </a>
                </button>
              </div>
            </div>
          </div>
        </div>
        <div className="h-third-container">
          <h4>What are Tailor-made Holidays?</h4>
          <p>
            A tailor-made holiday with Bliss Flights is all about creating your
            trip, your way. So, while we know our hand-picked itineraries are
            amazing, we also know that not all travelers are the same. That’s
            why we offer the tailor-made approach where we take the time to
            speak to you about what you’re looking for, consider your needs and
            budget, and custom-build a holiday to match. <br />
            And with thousands of Bliss Flights Travel Consultants across the
            globe, we have plenty of experience in crafting these bespoke trips,
            while offering help and travel advice along the way.
          </p>
          <h4>But why should I tailor make my holiday?</h4>
          <p>
            Tailor making a trip gives you the choice and flexibility to stay in
            the destination (or destinations!) you want, for the money you have
            available. With a tailor-made holiday, you will receive the best
            advice and your expert travel consultant will remove the
            time-consuming and stressful process of putting together your next
            holiday. Simply put: you’re in control. Not to mention you’ll have a
            holiday that no one else has – how’s that for a boast-worthy
            experience?
          </p>
          <h4>Is there a limit to the duration of my tailor-made holiday?</h4>
          <p>
            Absolutely not – stay for as little or as long as you like. And
            whether you want to cram loads of activities into a week or spread
            them out over three, we’ll build an itinerary that works for you.
          </p>
          <h4>Can I add a different destination to my tailor-made holiday?</h4>
          <p>
            Yes. Just let us know where else you’d like to go and how long for.
            We have some great stopover airfares too, perfect if you want to
            break up a long trip. For example, you can easily spend three nights
            in Dubai en route to Australia.
          </p>
        </div>
        <div className="h-forth-container">
          <div className="image-forth-container">
            <h2>WE KNOW IT'S NOT JUST TRAVEL MONEY</h2>
            <h5>It's discovering something new or really, really old</h5>
          </div>
        </div>
        <div className="h-third-container">
          <h4>What about the budget?</h4>
          <p>
            What about the budget? Tell us what you have to spend and we’ll make
            it work or, on the rare occasion we can’t, we’ll offer you a range
            of well-informed alternatives to meet your requirements.
          </p>
          <h4>
            And what if I want to spend a week relaxing and a week exploring?
          </h4>
          <p>
            That’s okay too. In fact, we know adventuring is thrilling but can
            also be tiring, which is why our Travel Consultants often create
            action-packed itineraries which feature relaxing resort holidays
            too.
          </p>
          <h4>Why should I book a tailor-made holiday with Bliss Flights?</h4>
          <p>
            We don’t mean to brag, but our Consultants really are the best
            people to book with. Not only will we create your ideal holiday for
            your ideal budget, but when you book a tailor-made holiday with
            Bliss Flights you’ll also enjoy access to your dedicated Travel
            Expert, before, during, and after you travel. As with all Bliss
            Flights holidays, you’ll also have you’ll have access to our 24-hour
            Emergency Assistance service (in the unlikely event something goes
            wrong while you’re away)
          </p>
          <h4>Top Bliss Flights Holidays</h4>
          <p>
            At Bliss Flights, our lovely Travel Consultants are dedicated to
            creating the perfect travel experience to suit your travel style,
            needs, and budget, and that's where our tailor-made holidays to
            Australia, the USA, Asia, and beyond come in…
          </p>
        </div>
      </div>
      <Footer />
    </>
  );
}
